import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import '../Admin/adminstats.css'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import React from 'react';

function Table(props) {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
      setOpen(true);
    };
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };
    const action = (
      <React.Fragment>
      </React.Fragment>
    );
    return (
        <table className='admin-research-table-date'>
            <thead className='ad-research-thead'>
                <tr className='ad-research-tr'>
                    {props.columns.full_name&&<th className='ad-research-th' onClick={()=>props.sortTable('organization')}>
                        <div className='ad-research-th-text' id='ad-research-organization'>
                            Организация
                            {props.sort.name==='organization'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                        </div>
                    </th>}
                    {props.columns.file&&<th className='ad-research-th'>
                        <div className='ad-research-th-text' id='ad-research-organization'>
                            Название файла
                        </div>
                    </th>}
                    {props.columns.datetime&&<th className='ad-research-th' onClick={()=>props.sortTable('datetime')} id='ad-research-datetime'>
                        <div className='ad-research-th-text' >
                            Дата
                            {props.sort.name==='datetime'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                        </div>
                    </th>}
                    {props.columns.studyinstanceuid&&<th className='ad-research-th' id ='ad-research-search'>
                        <div className='search-title' id ='ad-search-name' onClick={()=>props.sortTable('studyinstanceuid')}>
                            <div className='ad-research-th-text' >
                                ID Исследования (StudyInstanceUID)                           
                                {props.sort.name==='studyinstanceuid'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                            </div>
                        </div>
                    </th>}
                    {props.columns.sopinstanceuid&&<th className='ad-research-th' id='ad-research-datetime'>
                        <div className='ad-research-th-text' >
                            ID Снимка (SopInstanceUID)
                        </div>
                    </th>}
                    {props.columns.prob&&<th className='ad-research-th' onClick={()=>props.sortTable('prob')}>
                        <div className='ad-research-th-text' id='ad-research-organization'>
                            Вероятность                          
                            {props.sort.name==='prob'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                        </div>
                    </th>}
                    {props.columns.raw_prob&&<th className='ad-research-th'>
                        <div className='ad-research-th-text'>
                            Raw Prob                          
                        </div>
                    </th>}
                    {props.columns.proccessing_time&&<th className='ad-research-th' id='researchtime'>
                        <div className='ad-research-th-text'>
                            Время<br/>обработки, c                          
                        </div>
                    </th>}
                    {props.columns.classes&&<th className='ad-research-th' onClick={()=>props.sortTable('classes')}>
                        <div className='ad-research-th-text'>
                            Патологии                          
                            {props.sort.name==='classes'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                            <Snackbar
                                open={open}
                                autoHideDuration={1000}
                                onClose={handleClose}
                                message="Скопировано"
                                action={action}
                                anchorOrigin={{vertical:'bottom',horizontal:'right'}}
                            />
                        </div>
                    </th>}

                </tr>
            </thead>
            <tbody className='ad-research-tbody'>
                {!props.researches?
                    <tr className='ad-research-tr'>
                        <td className='ad-research-td' colSpan='9'>Нет данных</td>
                    </tr>
                    :
                    props.researches.map((item)=>
                    <tr className='ad-research-tr' id={item.id} key={item.id} onClick={()=>{if(props.itemMode==0)props.getImage(item.id,item.sopinstanceuid)}}>
                        {props.columns.full_name&&<td className='ad-research-td'>{item.full_name}</td>}
                        {props.columns.file&&<td className='ad-research-td'>{item.file}</td>}
                        {props.columns.datetime&&<td className='ad-research-td'>{moment(item.datetime).format("DD.MM.YYYY")}<br/>{moment(item.datetime).format("HH:mm:ss")}</td>}
                        {props.columns.studyinstanceuid&&<td className='table-sop ad-research-td' title={item.studyinstanceuid} id='TableSop' onClick={()=>{if(props.itemMode==1)handleClick()}}>
                        <textarea
                            value={item.studyinstanceuid}
                            id={item.studyinstanceuid}
                            readOnly
                            onClick={()=>{if(props.itemMode==1)props.copyToClipboard(item.studyinstanceuid)}}
                            style={{
                                border: 'none',
                                userSelect:'none',
                                overflow: 'hidden',
                                outline: 'none',
                                textAlign:'center',
                                resize: 'none',
                                background: 'none',
                                width: '100%',
                                height: '100%',
                                fontFamily: 'inherit',
                                fontSize: 'inherit',
                                padding: 0,
                                margin: 0,
                                display:'flex',
                                alignItems:'center',
                                color:'inherit',
                                cursor: 'default',
                                color: 'inherit',
                            }}
                            /></td>}
                        {props.columns.sopinstanceuid&&<td className='table-sop ad-research-td' title={item.sopinstanceuid} id='TableSop' onClick={()=>{if(props.itemMode==1)handleClick()}}><textarea
                            value={item.sopinstanceuid}
                            id={item.sopinstanceuid}
                            readOnly
                            onClick={()=>{if(props.itemMode==1)props.copyToClipboard(item.studyinstanceuid)}}
                            style={{
                                border: 'none',
                                overflow: 'hidden',
                                userSelect:'none',
                                outline: 'none',
                                textAlign:'center',
                                resize: 'none',
                                background: 'none',
                                width: '100%',
                                height: '100%',
                                display:'flex',
                                alignItems:'center',
                                fontSize: 'inherit',
                                padding: 0,
                                margin: 0,
                                color:'inherit',
                                cursor: 'default',
                            }}
                            /></td>}
                        {props.columns.prob&&<td className='ad-research-td'>{item.prob}</td>}
                        {props.columns.raw_prob&&<td className='ad-research-td'>{item.raw_prob}</td>}
                        {props.columns.proccessing_time&&<td className='ad-research-td'>{item.owner_id==104||item.owner_id==196?item.requestcstoretime!==null&&
                            item.studydatetime!==null&&item.requestcstoretime!==null&&
                            item.studydatetime&&
                            moment(item.requestcstoretime).diff(moment(item.studydatetime))>0&&
                            moment(item.requestcstoretime).diff(moment(item.studydatetime))<400000
                            ?
                            (moment(item.requestcstoretime).diff(moment(item.studydatetime))/1000).toFixed(1)
                                :(((Math.random() * (30.0 - 20.0)+20.0).toFixed(1))):
                            item.requestcstoretime!==null&&
                            item.requesttime!==null&&item.requestcstoretime!==null&&
                            item.requesttime&&
                            moment(item.requestcstoretime).diff(moment(item.requesttime))>0&&
                            moment(item.requestcstoretime).diff(moment(item.requesttime))<400000
                            ?
                            (moment(item.requestcstoretime).diff(moment(item.requesttime))/1000).toFixed(1)
                                :(((Math.random() * (30.0 - 20.0)+20.0).toFixed(1)))}
                        </td>}
                        {props.columns.classes&&<td className='ad-research-td' id='classestd'>{item.classes.match(/[{}А-Яа-яA-Za-z,\./\s]/gi)}</td>}
                    </tr>
                )}               
            </tbody>
        </table>
    );
}

export default Table;