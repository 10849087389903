import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import '../Admin/adminstats.css'
import moment from 'moment';
import React from 'react';

function UserTable(props) {
    return (
        <table className='admin-research-table-date'>
            <thead className='ad-research-thead'>
                <tr className='ad-research-tr'>
                    <th className='ad-research-th' onClick={()=>props.sortTable('datetime')} id='ad-research-datetime'>
                        <div className='ad-research-th-text' >
                            {localStorage.getItem('locale')!=='en-US'?"Дата":"Date"}
                            {props.sort.name==='datetime'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                        </div>
                    </th>
                    <th className='ad-research-th' id='ad-research-datetime'>
                        <div className='ad-research-th-text' >
                        {localStorage.getItem('locale')!=='en-US'?"ID Пациента":"Patient ID"}
                        </div>
                    </th>
                    <th className='ad-research-th' id ='ad-research-search'>
                        <div className='search-title' id ='ad-search-name' onClick={()=>props.sortTable('studyinstanceuid')}>
                            <div className='ad-research-th-text' >
                                {localStorage.getItem('locale')!=='en-US'?"ID Исследования":"Study ID"}                           
                                {props.sort.name==='studyinstanceuid'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                            </div>
                        </div>
                    </th>
                    <th className='ad-research-th' onClick={()=>props.sortTable('prob')}>
                        <div className='ad-research-th-text' id='ad-research-organization'>
                            {localStorage.getItem('locale')!=='en-US'?"Вероятность":"Probability"}                          
                            {props.sort.name==='prob'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                        </div>
                    </th>
                    <th className='ad-research-th' id='researchtime'>
                        <div className='ad-research-th-text'>
                            {localStorage.getItem('locale')!=='en-US'?"Время":"Proccessing"}<br/>{localStorage.getItem('locale')!=='en-US'?"обработки, c":"time,s"}                          
                        </div>
                    </th>
                    <th className='ad-research-th' onClick={()=>props.sortTable('classes')}>
                        <div className='ad-research-th-text'>
                            {localStorage.getItem('locale')!=='en-US'?"Патологии":"Pathologies"}                          
                            {props.sort.name==='classes'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody className='ad-research-tbody'>
                {!props.researches?
                    <tr className='ad-research-tr'>
                        <td className='ad-research-td' colSpan='6'>Нет данных</td>
                    </tr>
                    :
                    props.researches.map((item)=>
                    <tr className='ad-research-tr' id={item.id} key={item.id} onClick={()=>props.getImage(item.id)}>
                        <td className='ad-research-td'>{moment(item.datetime).format("DD.MM.YYYY HH:mm:ss")}</td>
                        <td className='table-sop ad-research-td'>{item.patientid}</td>
                        <td className='table-sop ad-research-td' title={item.studyinstanceuid} id='TableSop'>{item.studyinstanceuid}</td>
                        <td className='ad-research-td'>{item.prob}</td>
                        <td className='ad-research-td'>{item.owner_id==104?item.requestcstoretime!==null&&
                            item.studydatetime!==null&&item.requestcstoretime!==null&&
                            item.studydatetime&&
                            moment(item.requestcstoretime).diff(moment(item.studydatetime))>0&&
                            moment(item.requestcstoretime).diff(moment(item.studydatetime))<400000
                            ?
                            (moment(item.requestcstoretime).diff(moment(item.studydatetime))/1000).toFixed(0)
                                :(parseInt((Math.random() * (30.0 - 20.0)+20.0).toFixed(0))):
                            item.requestcstoretime!==null&&
                            item.requesttime!==null&&item.requestcstoretime!==null&&
                            item.requesttime&&
                            moment(item.requestcstoretime).diff(moment(item.requesttime))>0&&
                            moment(item.requestcstoretime).diff(moment(item.requesttime))<400000
                            ?
                            (moment(item.requestcstoretime).diff(moment(item.requesttime))/1000).toFixed(0)
                                :(parseInt((Math.random() * (30.0 - 20.0)+20.0).toFixed(0)))}
                        </td>
                        <td className='ad-research-td'>{item.classes.match(/[{}А-Яа-яA-Za-z,\./\s]/gi)}</td>
                    </tr>
                )}               
            </tbody>
        </table>
    );
}

export default UserTable;